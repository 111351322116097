<div class="container cards-container">
  <article
    class="card card--2"
    *ngFor="let company of medicalCompanies$ | async"
    [routerLink]="[generateLink(company.code)]"
  >
    <div class="card__img">
      <img [src]="company.logo" class="card-img-top card-image" alt="{{ company.name }}" />
    </div>
    <a class="card_link">
      <div class="card__img--hover">
        <img [src]="company.logo" class="card-img-top card-image" alt="{{ company.name }}" />
      </div>
    </a>
    <div class="card__info">
      <h3 class="card__title ps-4 pe-4 pt-3 fw-bolder text-primary fs-5 custom-title-card">
        {{ company.name }}
      </h3>
      <span class="card__category mb-2 text-body-secondary p-2">{{ company.address }}</span>
    </div>
 
  </article>
  
</div>
<div class="card__footer">
  <a class="text-muted mb-3" [routerLink]="['medical-record/consulting']">
    Do you have an assigned number to consult your medical report? Click here.
  </a>
</div>
