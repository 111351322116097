import { IUserAttachments } from '@interfaces/user/user-attachments.interface';

export class UserAttachmentsGetAction {
  static readonly type = '[UserAttachments] List';
}

export class UserAttachmentsGetByUserAction {
  static readonly type = '[UserAttachments] List By User';
  constructor(public id: number) {}
}

export class UserAttachmentsAddAction {
  static readonly type = '[UserAttachments] Add';
  constructor(public data: IUserAttachments) {}
}

export class UserAttachmentsEditAction {
  static readonly type = '[UserAttachments] Edit';
  constructor(public data: IUserAttachments) {}
}

export class UserAttachmentsUpdateAction {
  static readonly type = '[UserAttachments] Update';
  constructor(public data: IUserAttachments) {}
}

export class UserAttachmentsRemoveAction {
  static readonly type = '[UserAttachments] Remove';
  constructor(public id: number) {}
}

export class UserAttachmentsRequestSaveAction {
  static readonly type = '[UserAttachments] Request save';
}

export class UserAttachmentsSelectedRequestSaveAction {
  static readonly type = '[UserAttachments] Request save file';
  constructor(public selectedId: number) {}
}

export const ActionsToProcessUserAttachments = {
  UserAttachmentsGetAction,
  UserAttachmentsAddAction,
  UserAttachmentsUpdateAction,
  UserAttachmentsRemoveAction
};
