import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  UserAttachmentsGetAction,
  UserAttachmentsEditAction,
  UserAttachmentsAddAction,
  UserAttachmentsUpdateAction,
  UserAttachmentsRemoveAction,
  UserAttachmentsGetByUserAction
} from './user-attachments-actions';
import { Injectable } from '@angular/core';
import { UserAttachmentsService } from '@services/user/user-attachments.service';
import { IUserAttachments } from '@interfaces/user/user-attachments.interface';

export interface UserAttachmentsStateModel {
  list: IUserAttachments[];
  listFiltered: IUserAttachments[];
  edit: IUserAttachments;
}

export const StateUserAttachments = 'user_attachments';

@State<UserAttachmentsStateModel>({
  name: StateUserAttachments,
  defaults: {
    list: [],
    listFiltered: [],
    edit: undefined
  }
})
@Injectable()
export class UserAttachmentsState {
  @Selector() static listAttachmentsActive(state: UserAttachmentsStateModel) {
    return state.list.filter(i => i.active);
  }
  @Selector() static listAttachmentsActivePublic(state: UserAttachmentsStateModel) {
    return state.list.filter(i => i.active && i.type?.public);
  }
  @Selector() static listAttachmentsActivePrivate(state: UserAttachmentsStateModel) {
    return state.list.filter(i => i.active && !i.type?.public);
  }
  @Selector() static listAttachmentsByUser(state: UserAttachmentsStateModel) {
    return state.listFiltered.filter(i => i.active);
  }
  @Selector() static editAttachment(state: UserAttachmentsStateModel) {
    return state.edit;
  }

  constructor(private userAttachmentsService: UserAttachmentsService) {}

  @Action(UserAttachmentsGetAction)
  UserAttachmentsGet(ctx: StateContext<UserAttachmentsStateModel>) {
    return this.userAttachmentsService.get().pipe(
      tap(result => {
        ctx.patchState({
          list: result.data
        });
      })
    );
  }

  @Action(UserAttachmentsGetByUserAction)
  UserAttachmentsGetByUser(ctx: StateContext<UserAttachmentsStateModel>, { id }: UserAttachmentsGetByUserAction) {
    return this.userAttachmentsService.getByUserId(id).pipe(
      tap(result => {
        ctx.patchState({
          listFiltered: result.data
        });
      })
    );
  }

  @Action(UserAttachmentsEditAction)
  UserAttachmentsEdit(ctx: StateContext<UserAttachmentsStateModel>, { data }: UserAttachmentsEditAction) {
    ctx.patchState({
      edit: data
    });
  }

  @Action(UserAttachmentsAddAction)
  UserAttachmentsAdd(ctx: StateContext<UserAttachmentsStateModel>, { data }: UserAttachmentsAddAction) {
    return this.userAttachmentsService.save(data).pipe(
      tap(() => {
        ctx.patchState({ edit: data });
      })
    );
  }

  @Action(UserAttachmentsUpdateAction)
  UserAttachmentsUpdate(
    ctx: StateContext<UserAttachmentsStateModel | undefined>,
    { data }: UserAttachmentsUpdateAction
  ) {
    return this.userAttachmentsService.update(data).pipe(
      tap(() => {
        ctx.patchState({});
      })
    );
  }

  @Action(UserAttachmentsRemoveAction)
  UserAttachmentsRemove(ctx: StateContext<UserAttachmentsStateModel>, { id }: UserAttachmentsRemoveAction) {
    return this.userAttachmentsService.remove(id).pipe(
      tap(() => {
        ctx.patchState({});
      })
    );
  }
}
