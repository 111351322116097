import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import {
  AttachmentsTypesGetAction,
  AttachmentsTypesEditAction,
  AttachmentsTypesAddAction,
  AttachmentsTypesUpdateAction,
  AttachmentsTypesRemoveAction,
  AttachmentsTypesGetPublicAction,
  AttachmentsTypesSetShowDisabledAction,
  AttachmentsTypesGetOfferAction
} from './attachments-types-actions';
import { Injectable } from '@angular/core';
import { AttachmentsTypesService } from '@services/attachments-types.service';
import { IAttachmentsTypes } from '@interfaces/attachments-types.interface';

export interface AttachmentsTypesStateModel {
  list: IAttachmentsTypes[];
  listFiltered: IAttachmentsTypes[];
  edit: IAttachmentsTypes;
  show_disable: boolean;
}

export const StateAttachmentsTypes = 'attachments_types';

@State<AttachmentsTypesStateModel>({
  name: StateAttachmentsTypes,
  defaults: {
    list: [],
    listFiltered: [],
    show_disable: false,
    edit: null
  }
})
@Injectable()
export class AttachmentsTypesState {
  @Selector() static listAttachmentsTypesActivePublic(state: AttachmentsTypesStateModel) {
    return state.list.filter(i => i.active && i.public);
  }
  @Selector() static listAttachmentsTypesActivePrivate(state: AttachmentsTypesStateModel) {
    return state.list.filter(i => i.active && !i.public);
  }
  @Selector() static listAttachmentsTypesActive(state: AttachmentsTypesStateModel) {
    return state.list.filter(i => i.active);
  }
  @Selector() static list(state: AttachmentsTypesStateModel) {
    return state.list;
  }
  @Selector() static listFiltered(state: AttachmentsTypesStateModel) {
    return state.listFiltered.filter(i => i.active);
  }
  @Selector() static editTypeAttachment(state: AttachmentsTypesStateModel) {
    return state.edit;
  }
  @Selector() static showDisabled(state: AttachmentsTypesStateModel) {
    return state.show_disable;
  }

  constructor(private attachmentsTypesService: AttachmentsTypesService) {}

  @Action(AttachmentsTypesGetPublicAction)
  AttachmentsTypesGetPublic(ctx: StateContext<AttachmentsTypesStateModel>) {
    return this.attachmentsTypesService.getPublic().pipe(
      tap(result => {
        ctx.patchState({
          list: result.data
        });
      })
    );
  }

  @Action(AttachmentsTypesGetOfferAction)
  AttachmentsTypesGetOffer(ctx: StateContext<AttachmentsTypesStateModel>, { code }: AttachmentsTypesGetOfferAction) {
    return this.attachmentsTypesService.getOffer(code).pipe(
      tap(result => {
        ctx.patchState({
          listFiltered: result.data
        });
      })
    );
  }

  @Action(AttachmentsTypesGetAction)
  AttachmentsTypesGet(ctx: StateContext<AttachmentsTypesStateModel>) {
    return this.attachmentsTypesService.get().pipe(
      tap(result => {
        ctx.patchState({
          list: result.data
        });
      })
    );
  }

  @Action(AttachmentsTypesEditAction)
  AttachmentsTypesEdit(ctx: StateContext<AttachmentsTypesStateModel>, { data }: AttachmentsTypesEditAction) {
    ctx.patchState({
      edit: data
    });
  }

  @Action(AttachmentsTypesAddAction)
  AttachmentsTypesAdd(ctx: StateContext<AttachmentsTypesStateModel>, { data }: AttachmentsTypesAddAction) {
    return this.attachmentsTypesService.save(data).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(AttachmentsTypesUpdateAction)
  AttachmentsTypesUpdate(ctx: StateContext<AttachmentsTypesStateModel>, { data }: AttachmentsTypesUpdateAction) {
    return this.attachmentsTypesService.update(data).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(AttachmentsTypesRemoveAction)
  AttachmentsTypesRemove(ctx: StateContext<AttachmentsTypesStateModel>, { id }: AttachmentsTypesRemoveAction) {
    return this.attachmentsTypesService.remove(id).pipe(
      tap(() => {
        ctx.patchState({
          edit: null
        });
      })
    );
  }

  @Action(AttachmentsTypesSetShowDisabledAction)
  AttachmentsSetShowDisabled(
    ctx: StateContext<AttachmentsTypesStateModel>,
    { show }: AttachmentsTypesSetShowDisabledAction
  ) {
    ctx.patchState({
      show_disable: show
    });
  }
}
