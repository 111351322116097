import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ROUTES_PUBLIC } from '@data/app.constants';
import { IResponse } from '@interfaces/api.interface';
import { IUserAttachments } from '@interfaces/user/user-attachments.interface';

@Injectable({ providedIn: 'root' })
export class UserAttachmentsService {
  constructor(private httpClient: HttpClient) {}

  get() {
    return this.httpClient.get<IResponse<IUserAttachments[]>>(`${ROUTES_PUBLIC.USER_ATTACHMENTS}`);
  }

  getByUserId(id: number) {
    return this.httpClient.get<IResponse<IUserAttachments[]>>(`${ROUTES_PUBLIC.USER_ATTACHMENTS}/byUser/${id}`);
  }

  save(data: IUserAttachments) {
    return this.httpClient.post<IResponse<IUserAttachments>>(`${ROUTES_PUBLIC.USER_ATTACHMENTS}`, data);
  }

  update(data: IUserAttachments) {
    const id = data.id;
    delete data.id;
    return this.httpClient.put<IResponse<IUserAttachments>>(`${ROUTES_PUBLIC.USER_ATTACHMENTS}/${id}`, data);
  }

  remove(id: number) {
    return this.httpClient.delete<IResponse<IUserAttachments>>(`${ROUTES_PUBLIC.USER_ATTACHMENTS}/${id}`);
  }
}
